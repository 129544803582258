<template>
  <div
    v-if="article.paywall"
    data-testid="paywallArticle"
    class="shadow bg-white rounded-xl -mt-20 md:-mx-3 z-20 relative"
  >
    <div class="px-5 pt-8 pb-10 text-center">
      <div class="flex items-center justify-center mb-5 md:mb-10">
        <img src="/A_Logo.svg" alt="Altinget logo" class="h-9" />
        <span class="list-title-m text-blue ml-2">
          {{ currentPaper?.Name }}
        </span>
      </div>
      <!-- Paywall body -->
      <div class="promo-title px-0 md:px-10">
        <div
          v-if="
            userStore.isLoggedIn &&
            !userStore.hasConfirmedWorkEmail &&
            userStore.hasName &&
            userStore.user?.loginType === 'newuser'
          "
        >
          {{ $t('EmailNotConfirmed') }}
        </div>
        <div v-else>
          {{ $t('WantToReadArticle') }}
        </div>
        <div class="font-light">
          <template
            v-if="
              userStore.isLoggedIn &&
              !userStore.hasConfirmedWorkEmail &&
              userStore.hasName &&
              userStore.user?.loginType === 'newuser'
            "
          >
            {{ $t('activateemail') }}
          </template>
          <i18n-t
            v-else
            keypath="Paywall.articlePaywall"
            tag="div"
            scope="global"
          >
            <template #paper>
              {{
                // keep uppercase for EU and EU/EØS papers
                (isSite('altinget_no') && currentPaper?.Name == 'EU/EØS') ||
                currentPaper?.Name == 'EU'
                  ? currentPaper?.Name
                  : currentPaper?.Name?.toLowerCase()
              }}
            </template>
          </i18n-t>
        </div>
      </div>
      <transition name="skeleton" mode="out-in">
        <CommonLogin
          v-if="displayLogin"
          key="login"
          v-model:email="email"
          v-model:password="password"
          class="mt-8"
        >
          <div class="mt-2">
            <a
              class="list-label opacity-35 underline cursor-pointer"
              @click="showLogin = false"
            >
              {{ $t('Back') }}
            </a>
          </div>
        </CommonLogin>
        <div v-else key="promt">
          <div class="flex items-center justify-center mt-5">
            <template
              v-if="
                userStore.isLoggedIn &&
                !userStore.hasConfirmedWorkEmail &&
                userStore.hasName &&
                userStore.user?.loginType === 'newuser'
              "
            >
              <CommonSendActivationEmailButton />
            </template>
            <template v-else>
              <CommonButton
                v-if="!userStore.isLoggedIn"
                id="PaywallArticleLogin"
                class="mr-3"
                @click.native="toggleLogin"
              >
                {{ $t('Login') }}
              </CommonButton>
              <CommonButton
                id="PaywallCreateProfile"
                bg="bg-blue"
                text="text-white"
                border="border-none"
                @click.native="openTrialModal"
              >
                {{ $t('14dayTrial') }}
              </CommonButton>
            </template>
          </div>
          <div v-if="!userStore.isLoggedIn" class="body-xs opacity-50 mt-3">
            {{ $t('readMoreAboutPricesAndTerms') }}
            <NuxtLink class="underline" :to="{ name: 'terms' }">
              {{ $t('Here') }}
            </NuxtLink>
          </div>
        </div>
      </transition>
    </div>
    <div class="px-5 label-m text-red">
      {{ $t('LastestFrom') }} {{ currentPaper?.Name }}
    </div>
    <CommonDivider />
    <ListArticles
      class="px-5 pb-3 md:pb-6 pt-2"
      :paper="currentPaper?.RecordId"
      :count="3"
      :offset="2"
      :show-more="true"
      type="instream"
    />
  </div>
</template>

<script setup lang="ts">
import type { ArticleDTO, CalenderDTO } from '~/typesAuto/apicore/v2'
import type { ContentPaper } from '~/typesManual/content_api/paper'
import type { EcommerceItem } from '~/typesManual/dataLayer'

const props = defineProps<{ article: ArticleDTO | CalenderDTO }>()

const dataLayer = useDataLayer()
const trialModalState = useTrialModalState()

const userStore = useUserStore()
const indexStore = useIndexStore()

const showLogin = ref(false)
const email = ref('')
const password = ref('')

const displayLogin = computed(() => {
  return showLogin.value && !userStore.isLoggedIn
})

const currentPaper = computed(() => {
  if (
    indexStore.currentPaper?.RecordId === 1 &&
    props.article &&
    props.article.papers &&
    props.article.papers.length > 0
  ) {
    if (props.article.papers.find((x) => x.primary === 1)) {
      const paperid = props.article.papers.find((x) => x.primary === 1)?.paperId
      return indexStore.papers.find((x) => x.RecordId === paperid)
    } else {
      const paperList = props.article.papers
      const paperid = paperList.sort(
        (a, b) =>
          new Date(a.publishingDate ?? 0).getTime() -
          new Date(b.publishingDate ?? 0).getTime()
      )[0].paperId
      return indexStore.papers.find((x) => x.RecordId === paperid)
    }
  } else {
    return indexStore.currentPaper
  }
})

const ecommerceItem: EcommerceItem = {
  item_id: currentPaper.value?.Product?.[0]?.RecordId,
  item_name: currentPaper.value?.Product?.[0]?.Name,
  item_list_id: 'paywall_article',
  price: 0,
}

function toggleLogin() {
  showLogin.value = true
}

if (props.article.paywall) {
  dataLayer.trackPaywall()
  dataLayer.ecommerce.viewItem(ecommerceItem)
}

function openTrialModal() {
  trialModalState.value.papers = [currentPaper.value] as ContentPaper[]
  trialModalState.value.open = true

  dataLayer.ecommerce.beginCheckout([ecommerceItem])
}
</script>
