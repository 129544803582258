<template>
  <div
    v-if="showPaywall"
    class="shadow rounded-xl"
    :class="{ '-mt-20 md:-mx-3 z-20 relative': placement === 'article' }"
    :style="{ backgroundColor: '#fff' }"
  >
    <div class="px-5 pt-8 md:pt-15 pb-8 md:pb-12 text-center">
      <div class="list-title-m">
        <template v-if="$route.name === 'article'">
          Vil du have adgang til artiklen?
        </template>
        <template v-else-if="$route.name === 'paper'">
          Vil du blive klogere på {{ currentPaper?.Name }}?
        </template>
        <template v-else> Vil du have adgang til Mandag Morgen? </template>
      </div>
      <div class="list-title-m !font-normal mb-6">
        Så skal du have et abonnement
      </div>

      <template v-if="$route.name === 'paper'">
        <div class="body-s max-w-xl mx-auto">
          <p>
            Med Mandag Morgen bliver du klædt på til fremtiden indenfor
            <b>{{ currentPaper?.Name }}</b> og Mandag Morgens øvrige
            fokusområder. Benyt knappen herunder, så kontakter vores
            salgsafdeling dig med et uforpligtende tilbud.
          </p>
        </div>
      </template>

      <div class="list-title-s max-w-xl mx-auto !font-normal">
        <template v-if="$route.name === 'article'">
          Mandag Morgen giver dig <b>solid viden</b> om de centrale spørgsmål,
          der forandrer samfundet. Her får du dybde og sammenhæng, så du
          <b>kan læse mindre og forstå mere</b>.
        </template>
        <template v-else-if="$route.name !== 'paper'">
          Få adgang til artiklen og resten af Mandag Morgen ved at benytte
          knappen herunder, så kontakter vores salgsafdeling dig med et
          uforpligtende tilbud.
        </template>
      </div>
      <transition name="skeleton" mode="out-in">
        <CommonLogin
          v-if="showLogin && !hasSubscription"
          key="login"
          class="mt-8"
          v-model:email="email"
          v-model:password="password"
        >
          <div class="mt-2">
            <a
              class="list-label opacity-35 underline cursor-pointer"
              @click="showLogin = false"
            >
              {{ $t('Back') }}
            </a>
          </div>
        </CommonLogin>
        <div v-else key="promt">
          <div class="flex flex-wrap items-center justify-center mt-5 gap-3">
            <CommonButton
              id="PaywallLeadForm"
              bg="bg-blue"
              text="text-white"
              border="border-none"
              class="flex-grow md:flex-grow-0"
              @click="openTrialModal"
            >
              Ja tak, giv mig et uforpligtende tilbud
            </CommonButton>
            <CommonButton
              v-if="!hasSubscription"
              @click.native="toggleLogin"
              class="flex-grow md:flex-grow-0"
            >
              Log ind
            </CommonButton>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    placement?: 'article' | 'main'
  }>(),
  {
    placement: 'article',
  }
)

const showLogin = ref(false)
const email = ref('')
const password = ref('')

const indexStore = useIndexStore()
const userStore = useUserStore()

const currentPaper = computed(() => indexStore.currentPaper)

const hasSubscription = computed(() =>
  userStore.user?.papers.find((paper) => paper === 1)
)

const showPaywall = computed(() => !hasSubscription.value)

const dataLayer = useDataLayer()

onMounted(() => {
  if (showPaywall.value) {
    dataLayer.trackPaywall()
  }
})

function toggleLogin() {
  showLogin.value = true
}

const trialModalState = useTrialModalState()
function openTrialModal() {
  trialModalState.value.papers = [{ RecordId: 1 }]
  trialModalState.value.open = true
}
</script>

<style lang="scss" scoped>
div {
  :deep() {
    .swiper-slide {
      width: 260px !important;
    }
  }
}
</style>
