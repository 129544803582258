<template>
  <div ref="articleContentRef">
    <div
      v-if="articleTexts?.[0]"
      v-html="articleTexts[0]"
      class="content-dynamic flow-root"
      :class="{ 'mb-8 md:mb-16': !articleTexts[1] }"
    />
    <template v-if="article.paywall">
      <div
        v-if="articleTexts?.[1]"
        class="mb-4 content-dynamic flow-root"
        v-html="articleTexts[1]"
      />
      <div class="clear-both" />
      <CommonMmPaywall v-if="isMm()" />
      <CommonPaywallArticle :article="article" v-else />
    </template>
    <template v-for="(text, index) in articleTexts?.slice(1)">
      <template v-if="sponsoredLinks && sponsoredLinks[index]">
        <div
          :key="'top1' + index"
          class="text-red label-m mt-4 mb-2 md:mb-6 clear-both no-print"
        >
          {{ $t('ReadAlso') }}
        </div>
        <ArticleSponsoredTeaser
          class="border border-gray-400 mb-4 md:mb-6"
          :article="sponsoredLinks[index]"
          :key="index"
        />
      </template>
      <template v-else-if="topLinks && topLinks[index]">
        <div
          :key="'top1' + index"
          class="text-red label-m mt-4 mb-2 md:mb-6 clear-both no-print"
        >
          {{ $t('ReadAlso') }}
        </div>
        <ArticleTeaser
          :key="'top2' + index"
          :index="index"
          :sameSize="true"
          :article="topLinks[index]"
          :in-text="true"
          class="mb-4 md:mb-6 no-print"
        />
      </template>
      <div
        v-else-if="![SPONSORED_CONTENT, TOPLINK].includes(text)"
        class="mb-4 content-dynamic flow-root"
        v-html="text"
      />
    </template>
    <div id="article-content-end" class="w-full h-px block"></div>
  </div>
</template>

<script setup lang="ts">
import type { ArticleDTO, CalenderDTO } from '~/typesAuto/apicore/v2'

const nuxtApp = useNuxtApp()

const indexStore = useIndexStore()

const SPONSORED_CONTENT = '{{sponsoredContent}}'
const TOPLINK = '{{toplink}}'

const props = defineProps<{
  article: ArticleDTO | CalenderDTO
}>()

const articleContentRef = ref<HTMLElement | null>(null)

const articleText = computed(() => {
  return isCalendarDTO(props.article)
    ? props.article.description
    : props.article.text
})

function isCalendarDTO(
  object: ArticleDTO | CalenderDTO
): object is CalenderDTO {
  return (object as CalenderDTO).organizor !== undefined
}

const { data: sponsoredTeasers } = await useAsyncData(async () => {
  if (!articleText.value?.includes(SPONSORED_CONTENT)) {
    return null
  }
  return await nuxtApp.$api.articles.getSponsoredArticles({
    paperId: indexStore.currentPaper?.RecordId ?? 1,
  })
})

const articleTexts = computed(() => {
  if (!articleText.value) {
    return []
  }

  // TODO remove this fix after html is expected to be always valid from backed.
  // Right now hotshot has allows divs in article text and if that is added and the the text gets cut due to either paywalls or the splits due to toplink or sponsoredContent
  // the html can get malformed due to this, and will result in some unexpected bahavior. On refresh or on a page which such a text.
  // The code bellow is a frontend fix to make sure that the html is always valid and does not break other parts of the site.
  const texts = articleText.value.split(/({{toplink}}|{{sponsoredContent}})/)
  for (let i = 0; i < texts.length; i++) {
    const startDiv = /<div.*?>/gi
    const endDiv = /<\/div>/gi
    const startDivNr = [...texts[i].matchAll(startDiv)].length
    const endDivNr = [...texts[i].matchAll(endDiv)].length
    if (startDivNr > endDivNr) {
      texts[i] = `${texts[i]}${'</div>'.repeat(startDivNr - endDivNr)}`
    } else if (startDivNr < endDivNr) {
      texts[i] = `${'<div>'.repeat(endDivNr - startDivNr)}${texts[i]}`
    }
  }

  return texts
})

// emits
const emit = defineEmits<{
  dynamicArticleList: [list: (HTMLParagraphElement | HTMLQuoteElement)[]]
}>()

const emitArticleContentList = () => {
  if (!articleContentRef.value) return
  const contents = articleContentRef.value.querySelectorAll(
    '.content-dynamic > p, .content-dynamic > blockquote'
  ) as NodeListOf<HTMLParagraphElement | HTMLQuoteElement>

  // filter out empty elements
  const filteredContent = Array.from(contents).filter((el) => el.textContent)

  emit('dynamicArticleList', filteredContent)
}

watch(
  () => props.article,
  async () => {
    await nextTick()
    emitArticleContentList()
  }
)

onMounted(() => {
  emitArticleContentList()
})

/**
 * Create a map from index in articleTexts to toplink/sponsoredcontent/whatever we will add in the future.
 * In the template, we'll iterate over articleTexts. An articleText can either be some text or a component like {{toplink}} or {{sponsoredContent}}
 * If it's a component, we need to find the matching data to insert into the component. We use this
 * function to generate the matching data.
 * For toplinks we can look in `article.toplinks` and for sponsored content we can look in `sponsoredTeasers`
 */
function findMatchingIndices<T>(
  texts: string[],
  searchString: string,
  data: T[]
): Record<number, T> {
  const result: Record<number, T> = {}
  let searchIndex = 0

  for (const [i, text] of texts.slice(1).entries()) {
    if (text === searchString) {
      const noMoreMatchingContent = !data[searchIndex]
      if (noMoreMatchingContent) {
        return result
      }
      result[i] = data[searchIndex]
      searchIndex++
    }
  }

  return result
}

const sponsoredLinks = computed(() => {
  if (!sponsoredTeasers.value || !articleTexts.value) {
    return
  }
  return findMatchingIndices(
    articleTexts.value,
    SPONSORED_CONTENT,
    sponsoredTeasers.value.data
  )
})

const topLinks = computed(() => {
  if (!articleTexts.value) return
  return findMatchingIndices(
    articleTexts.value,
    TOPLINK,
    props.article.topLinks || []
  )
})
</script>
<style lang="scss" scoped>
.iframe {
  :deep() {
    iframe {
      width: 100%;
      height: 660px;
    }
  }
}
.content {
  max-width: 720px;
  min-width: 300px;
  flex-basis: auto;
  flex-grow: 1;
}
.writer-description {
  :deep() {
    p {
      margin-bottom: 10px;
    }
  }
}
.sidebar {
  :deep() {
    > div {
      @apply mb-10;
      @screen md {
        @apply mb-4;
        &:last-child {
          @apply mb-0;
        }
      }
      @screen lg {
        @apply mb-8;
        &:last-child {
          @apply mb-0;
        }
      }
      @screen xl {
        @apply mb-12;
        &:last-child {
          @apply mb-0;
        }
      }
    }
  }
}

.image {
  figcaption {
    caption-side: bottom;
  }
}
</style>
<style>
.enlargeFirstletter > p:first-child::first-letter {
  font-family: EamesCenturyModern;
  font-size: 60px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #282828;
  line-height: 45px;
  float: left;
  padding-right: 3px;
}

table {
  background-color: transparent;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #9aa2a5;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fcfdfd;
}

.table-condensed > thead > tr > th,
.widget-documentation .table > thead > tr > th,
.table-condensed > thead > tr > td,
.widget-documentation .table > thead > tr > td,
.table-condensed > tbody > tr > th,
.widget-documentation .table > tbody > tr > th,
.table-condensed > tbody > tr > td,
.widget-documentation .table > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.widget-documentation .table > tfoot > tr > th,
.table-condensed > tfoot > tr > td,
.widget-documentation .table > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*='col-'] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*='col-'],
table th[class*='col-'] {
  position: static;
  float: none;
  display: table-cell;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
</style>
